<template>
  <div class="ForgotPassword">
    <div class="ForgotPassword--Header">
      <svg class="bg-logo" width="175px" height="33px" viewBox="0 0 397 74" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="logo" fill="#FFFFFF">
            <path d="M66.7975,33.608 L80.9475,33.608 C80.9475,30.11 78.9435,26.675 74.2995,26.675 C70.1575,26.675 67.1555,29.032 66.7975,33.608 M74.2995,20.104 C86.3775,20.104 88.5925,29.389 88.5925,34.323 C88.5925,36.968 88.5925,40.04 84.2335,40.04 L66.5805,40.04 C66.5805,45.183 70.2265,47.542 74.9435,47.542 C78.0845,47.542 80.3035,46.471 82.0165,45.324 C82.8035,44.827 83.3755,44.542 84.3055,44.542 C86.1635,44.542 87.6615,46.046 87.6615,47.898 C87.6615,49.04 87.0895,49.969 86.4475,50.542 C85.2335,51.683 81.4465,54.477 74.9435,54.477 C65.0105,54.477 58.8635,49.117 58.8635,37.179 C58.8635,26.249 64.5795,20.104 74.2995,20.104" id="Fill-1"></path>
            <path d="M126.9264,41.6138 L126.9264,39.6138 L118.5644,39.6138 C115.4224,39.6138 113.7074,41.1118 113.7074,43.6858 C113.7074,46.4008 115.6384,48.0468 119.6394,48.0468 C123.4264,48.0468 126.9264,45.6098 126.9264,41.6138 M113.2764,29.6758 C112.5644,30.1798 111.9914,30.3898 111.1324,30.3898 C109.2784,30.3898 107.7734,28.8938 107.7734,27.0318 C107.7734,26.2488 108.0644,25.3868 108.7044,24.6748 C110.7054,22.4618 114.2774,20.1038 120.4254,20.1038 C129.2834,20.1038 134.5714,24.4638 134.5714,32.0358 L134.5714,50.3278 C134.5714,52.3988 132.8564,54.1128 130.7844,54.1128 C128.7134,54.1128 126.9954,52.3988 126.9954,50.3278 C125.0684,53.3978 121.9244,54.4768 117.6384,54.4768 C110.6354,54.4768 105.9904,50.1178 105.9904,43.6858 C105.9904,37.0378 111.3504,33.6078 117.5634,33.6078 L126.9264,33.6078 L126.9264,32.3208 C126.9264,29.1038 124.5694,26.9618 120.4254,26.9618 C117.2804,26.9618 115.0654,28.3888 113.2764,29.6758" id="Fill-3"></path>
            <path d="M149.0797,24.7447 C150.4357,21.9577 153.7967,20.1747 156.7977,20.1747 C158.7997,20.1747 160.4397,21.8187 160.4397,23.8197 C160.4397,25.8207 158.7997,27.4657 156.7977,27.4657 C151.8677,27.4657 149.0797,30.6077 149.0797,35.3937 L149.0797,50.2567 C149.0797,52.3987 147.3637,54.1127 145.2217,54.1127 C143.0747,54.1127 141.3617,52.3987 141.3617,50.2567 L141.3617,24.3877 C141.3617,22.2457 143.0747,20.5317 145.2217,20.5317 C147.3637,20.5317 149.0797,22.2457 149.0797,24.3877 L149.0797,24.7447 L149.0797,24.7447 Z" id="Fill-5"></path>
            <path d="M169.0563,33.608 L183.2013,33.608 C183.2013,30.11 181.2013,26.675 176.5613,26.675 C172.4123,26.675 169.4163,29.032 169.0563,33.608 M176.5613,20.104 C188.6363,20.104 190.8493,29.389 190.8493,34.323 C190.8493,36.968 190.8493,40.04 186.4923,40.04 L168.8423,40.04 C168.8423,45.183 172.4883,47.542 177.2043,47.542 C180.3443,47.542 182.5603,46.471 184.2773,45.324 C185.0613,44.827 185.6353,44.542 186.5633,44.542 C188.4223,44.542 189.9203,46.046 189.9203,47.898 C189.9203,49.04 189.3503,49.969 188.7063,50.542 C187.4923,51.683 183.7033,54.477 177.2043,54.477 C167.2723,54.477 161.1243,49.117 161.1243,37.179 C161.1243,26.249 166.8423,20.104 176.5613,20.104" id="Fill-7"></path>
            <path d="M211.5192,17.2642 C211.5192,15.1922 213.2302,13.4712 215.3042,13.4712 C217.3772,13.4712 219.0932,15.1922 219.0932,17.2642 L219.0932,20.2462 L222.3042,20.2462 C224.0962,20.2462 225.5252,22.0162 225.5252,23.8002 C225.5252,25.5862 224.0962,27.0202 222.3042,27.0202 L219.0932,27.0202 L219.0932,44.8262 C219.0932,46.4712 220.5212,47.4022 221.8112,47.4022 C223.6672,47.4022 225.1642,48.8992 225.1642,50.7602 C225.1642,52.6142 223.6672,54.1132 221.8112,54.1132 C215.4482,54.1132 211.5192,50.0452 211.5192,44.3992 L211.5192,27.0202 L209.8732,27.0202 C208.0872,27.0202 206.6572,25.5862 206.6572,23.8002 C206.6572,22.0162 208.0872,20.2462 209.8732,20.2462 L211.5192,20.2462 L211.5192,17.2642 L211.5192,17.2642 Z" id="Fill-9"></path>
            <path d="M237.3854,33.608 L251.5344,33.608 C251.5344,30.11 249.5334,26.675 244.8894,26.675 C240.7444,26.675 237.7414,29.032 237.3854,33.608 M244.8894,20.104 C256.9634,20.104 259.1784,29.389 259.1784,34.323 C259.1784,36.968 259.1784,40.04 254.8214,40.04 L237.1714,40.04 C237.1714,45.183 240.8154,47.542 245.5334,47.542 C248.6754,47.542 250.8914,46.471 252.6084,45.324 C253.3924,44.827 253.9664,44.542 254.8934,44.542 C256.7504,44.542 258.2524,46.046 258.2524,47.898 C258.2524,49.04 257.6824,49.969 257.0374,50.542 C255.8224,51.683 252.0334,54.477 245.5334,54.477 C235.5984,54.477 229.4544,49.117 229.4544,37.179 C229.4544,26.249 235.1714,20.104 244.8894,20.104" id="Fill-11"></path>
            <path d="M272.1217,29.5377 C272.1217,34.8207 289.6967,32.3917 289.6967,44.1827 C289.6967,50.9007 283.9787,54.3287 276.6227,54.3287 C270.4747,54.3287 265.7607,51.5437 263.9737,49.3327 C263.4007,48.6137 263.1167,47.9677 263.1167,47.1157 C263.1167,45.2547 264.6857,43.6857 266.5457,43.6857 C267.2597,43.6857 267.9727,43.8947 268.7597,44.5417 C270.6907,46.1137 273.0497,47.4717 276.6227,47.4717 C279.8377,47.4717 281.9087,46.6117 281.9087,44.6097 C281.9087,38.8237 264.4027,41.8237 264.4027,29.6767 C264.4027,23.2457 269.7607,20.1037 276.3357,20.1037 C281.4087,20.1037 285.9117,22.0297 287.9127,24.5337 C288.3387,25.1077 288.6267,25.6037 288.6267,26.6757 C288.6267,28.5357 287.0547,30.1097 285.1977,30.1097 C284.1967,30.1097 283.5537,29.6767 282.7667,29.1037 C281.1937,27.9637 279.2667,26.8227 276.3357,26.8227 C273.6897,26.8227 272.1217,27.7457 272.1217,29.5377" id="Fill-13"></path>
            <path d="M297.4088,17.2642 C297.4088,15.1922 299.1228,13.4712 301.1958,13.4712 C303.2698,13.4712 304.9838,15.1922 304.9838,17.2642 L304.9838,20.2462 L308.1988,20.2462 C309.9868,20.2462 311.4158,22.0162 311.4158,23.8002 C311.4158,25.5862 309.9868,27.0202 308.1988,27.0202 L304.9838,27.0202 L304.9838,44.8262 C304.9838,46.4712 306.4158,47.4022 307.7018,47.4022 C309.5568,47.4022 311.0578,48.8992 311.0578,50.7602 C311.0578,52.6142 309.5568,54.1132 307.7018,54.1132 C301.3428,54.1132 297.4088,50.0452 297.4088,44.3992 L297.4088,27.0202 L295.7648,27.0202 C293.9798,27.0202 292.5498,25.5862 292.5498,23.8002 C292.5498,22.0162 293.9798,20.2462 295.7648,20.2462 L297.4088,20.2462 L297.4088,17.2642 L297.4088,17.2642 Z" id="Fill-15"></path>
            <path d="M322.8326,33.608 L336.9816,33.608 C336.9816,30.11 334.9806,26.675 330.3336,26.675 C326.1906,26.675 323.1896,29.032 322.8326,33.608 M330.3336,20.104 C342.4126,20.104 344.6256,29.389 344.6256,34.323 C344.6256,36.968 344.6256,40.04 340.2666,40.04 L322.6146,40.04 C322.6146,45.183 326.2606,47.542 330.9776,47.542 C334.1196,47.542 336.3366,46.471 338.0506,45.324 C338.8356,44.827 339.4096,44.542 340.3386,44.542 C342.1976,44.542 343.6936,46.046 343.6936,47.898 C343.6936,49.04 343.1256,49.969 342.4816,50.542 C341.2676,51.683 337.4786,54.477 330.9776,54.477 C321.0456,54.477 314.8976,49.117 314.8976,37.179 C314.8976,26.249 320.6126,20.104 330.3336,20.104" id="Fill-17"></path>
            <path d="M357.7027,24.7447 C359.0607,21.9577 362.4197,20.1747 365.4217,20.1747 C367.4237,20.1747 369.0647,21.8187 369.0647,23.8197 C369.0647,25.8207 367.4237,27.4657 365.4217,27.4657 C360.4907,27.4657 357.7027,30.6077 357.7027,35.3937 L357.7027,50.2567 C357.7027,52.3987 355.9887,54.1127 353.8487,54.1127 C351.7037,54.1127 349.9877,52.3987 349.9877,50.2567 L349.9877,24.3877 C349.9877,22.2457 351.7037,20.5317 353.8487,20.5317 C355.9887,20.5317 357.7027,22.2457 357.7027,24.3877 L357.7027,24.7447 L357.7027,24.7447 Z" id="Fill-19"></path>
            <path d="M379.0045,29.5377 C379.0045,34.8207 396.5825,32.3917 396.5825,44.1827 C396.5825,50.9007 390.8655,54.3287 383.5065,54.3287 C377.3595,54.3287 372.6475,51.5437 370.8585,49.3327 C370.2845,48.6137 369.9985,47.9677 369.9985,47.1157 C369.9985,45.2547 371.5725,43.6857 373.4305,43.6857 C374.1455,43.6857 374.8585,43.8947 375.6455,44.5417 C377.5735,46.1137 379.9345,47.4717 383.5065,47.4717 C386.7235,47.4717 388.7945,46.6117 388.7945,44.6097 C388.7945,38.8237 371.2855,41.8237 371.2855,29.6767 C371.2855,23.2457 376.6475,20.1037 383.2205,20.1037 C388.2925,20.1037 392.7955,22.0297 394.7965,24.5337 C395.2255,25.1077 395.5125,25.6037 395.5125,26.6757 C395.5125,28.5357 393.9405,30.1097 392.0815,30.1097 C391.0805,30.1097 390.4395,29.6767 389.6515,29.1037 C388.0805,27.9637 386.1515,26.8227 383.2205,26.8227 C380.5765,26.8227 379.0045,27.7457 379.0045,29.5377" id="Fill-21"></path>
            <path d="M20.4137,54.4556 C19.1157,54.4556 17.9077,53.7846 17.2217,52.6806 L0.5667,25.8856 C-0.5283,24.1216 0.0117,21.8036 1.7757,20.7066 C3.5377,19.6116 5.8567,20.1506 6.9537,21.9166 L20.2777,43.3516 L43.9607,1.8946 C44.9907,0.0926 47.2867,-0.5344 49.0907,0.4966 C50.8937,1.5266 51.5197,3.8236 50.4907,5.6276 L23.6787,52.5596 C23.0217,53.7096 21.8087,54.4306 20.4827,54.4536 C20.4607,54.4556 20.4367,54.4556 20.4137,54.4556" id="Fill-23"></path>
            <path d="M37.9518,54.4556 C36.6538,54.4556 35.4458,53.7846 34.7598,52.6806 L18.1048,25.8856 C17.0098,24.1216 17.5498,21.8036 19.3138,20.7066 C21.0758,19.6116 23.3958,20.1506 24.4918,21.9166 L37.8158,43.3516 L61.4988,1.8946 C62.5288,0.0926 64.8248,-0.5344 66.6288,0.4966 C68.4318,1.5266 69.0578,3.8236 68.0288,5.6276 L41.2178,52.5596 C40.5598,53.7096 39.3468,54.4306 38.0218,54.4536 C37.9988,54.4556 37.9748,54.4556 37.9518,54.4556" id="Fill-25"></path>
        </g>
      </svg>
    </div>
    <form>
      <h1>{{ $t('suite_forgotpsw_title') }}</h1>
      <div class="ForgotPassword--Instructions" v-show="!showNewPassword">
        <p>{{ $t('suite_forgotpsw_text1') }}</p>
        <p>{{ $t('suite_forgotpsw_text2') }}</p>
      </div>
      <div class="ForgotPassword--Content" v-show="successMessage.length == 0 && !showNewPassword">
        <SuiteInput class="Floating" ref="Email" type="text" :label="$t('suite_form_email')" v-model="email" :required="true"/>
        <Button v-on:click="logIn()" bgColor="#96C800">
          {{ $t('suite_action_enter') }}
        </Button>
      </div>
      <div class="ForgotPassword--Content" v-show="showNewPassword && successMessage.length == 0">
        <SuiteInput class="Floating" ref="Password" type="password" :label="$t('suite_form_pass')" v-model="password" :required="true" :minLength="6"/>
        <SuiteInput class="Floating" ref="PasswordRepeat" type="password" :label="$t('suite_form_pass_repeat')" v-model="passwordRepeat" :required="true" :minLength="6"/>
        <Button v-on:click="newPassword()" bgColor="#96C800">
          {{ $t('suite_action_change_password') }}
        </Button>
      </div>
      <div class="ForgotPassword--Content ForgotPassword--Success" v-show="successMessage.length > 0">
        {{successMessage}}
      </div>
      <p v-on:click="$router.push({name:'login'})">{{ $t('suite_action_go_login') }}</p>
      <span v-show="loginError.length > 0">{{loginError}}</span>
    </form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Button } from '@/components'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import store from '@/store'
export default defineComponent({
  name : 'ForgotPassword',
  components:{
    SuiteInput,
    Button
  },
  data(){
    return{
      email:"",
      password:"",
      passwordRepeat:"",
      loginError:"",
      successMessage: "",
      urlParams:{
        userId:null,
        codeAction: null
      },
      showNewPassword: false
    }
  },
  methods:{
    logIn(){
      this.loginError = ""
      let errors = [
        this.$refs.Email.hasErrors()
      ]
      if(errors.includes(true)) return

      store.dispatch('resetPassword',{email:this.email})
      .then((data) => {
        this.successMessage = this.$t('suite_forgotpsw_success')
        this.loginError = ""
      })
      .catch((response)  => {
        this.loginError = this.$t('suite_forgotpsw_error')
      })
    },
    newPassword(){
      let errors = [
        this.$refs.Password.hasErrors(),
        this.$refs.PasswordRepeat.hasErrors()
      ]
      if(errors.includes(true)) return
      if(this.password != this.passwordRepeat){
        this.loginError = this.$t('suite_forgotpsw_error_psw_repeat')
        return
      }
      store.dispatch('resetNewPassword',{userId:this.urlParams.userId,data:{password:this.password,codeAction:this.urlParams.codeAction}})
      .then((data) => {
        this.successMessage = this.$t('suite_forgotpsw_success_updated')
        this.loginError = ""
      })
      .catch((response)  => {
        this.loginError = this.$t('suite_forgotpsw_error_control_code')
      })
    },
    checkResetCode(){
      store.dispatch('checkResetCode',{userId:this.urlParams.userId,data:{codeAction:this.urlParams.codeAction}})
      .catch((response)  => {
        this.loginError = this.$t('suite_forgotpsw_error_control_code')
      })
    }
  },
  async mounted(){
    this.urlParams = this.$route.query
    if(this.urlParams.userId != undefined){
      this.showNewPassword = true
      await this.checkResetCode()
    }
  }
})
</script>

<style scoped lang="scss">
.ForgotPassword{
  width: fit-content;
  background: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  width: 500px;
  h1{
    font-family: Roboto Condensed;
    font-weight: bold;
    font-size: 25px;
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
  }
  &--Header{
    background: url("../assets/img/waves-bg-2.png"),linear-gradient(70.59deg, #361466 5.34%, #895AD2 96.14%);
    padding: 20px 0 13px 0;
  }
  &--Instructions{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p{
      color: #74818C!important;
      font-size: 14px!important;
      margin: 0 0 10px 0!important;
      text-align: left;
    }
  }
  &--Content{
    width: 100%;
  }
  &--Success{
    background: #dff0d8;
    border:1px solid #d6e9c6;
    color: #3c763d;
    padding: 15px;
    margin-bottom: 10px 0;
    border-radius: 3px;
  }
  .Input{
    margin-bottom: 20px;
  }
  .Button{
    width: 100%;
    font-size: 14px;
  }
  form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
  }
  span{
    color:red;
    margin-top: 10px;
  }
  p{
    color: #8134ff;
    font-size: 12px;
    margin-top: 30px;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
  @media (max-width: 480px) {
    form{
      padding: 20px;
    }
  }
}
</style>
